
.common-loading{
	position: relative;
	margin:0 auto;
	.outer{
		animation: 1s loadingrotate linear infinite;
		-webkit-animation: 1s loadingrotate linear infinite;
	}
	.inner{
		position: absolute;
		top:0;
		left:0;
	}
	#svg-font {
        font-size: 26px;
        fill: #fff;
        text-anchor: middle;
        font-family: Arial;
     }
}
@keyframes loadingrotate{
	0% {
		transform:rotateZ(0deg)
	}
	100% {
		transform:rotateZ(360deg)
	}
}
@-webkit-keyframes loadingrotate{
	0% {
		-webkit-transform:rotateZ(0deg)
	}
	100% {
		-webkit-transform:rotateZ(360deg)
	}
}
