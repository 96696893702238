
.img-tips {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
        width: 1.83rem;
        height: 1.83rem;
    }

    .letter {
        font-weight: 400;
        // margin-top: -0.17rem;
        font-size: 0.27rem;
        font-family: SFNS-Regular, SFNS;
    }
}
