
.common-radio_wrap {
    display: flex;
}

.flex-column {
    flex-direction: column;
}

.flex-row {
    flex-direction: row;
}

.common-radio_item {
    display: flex;
    justify-content: flex-start;
    margin-bottom: .41rem;
    cursor: pointer;
}

.common-radio_icon {
    width: .27rem;
    margin-right: .14rem;

    img {
        display: inline-block;
        width: 100%;
    }
}

.common-radio_label {
    text-align: left;
}

.flex1 {
    flex: 1;
}

.ommon-radio_item {
    display: flex;
}