 
.group-cell {
    display: flex;
    flex-direction: column;
}
.account-describe_tip {
    display: flex;
    align-items: center;
    margin-bottom: .31rem;
    img {
        margin-right: .2rem;
    }
}
