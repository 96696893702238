
.hrefStyle {
  color: #1483FF;
}
@media screen and (min-width:1200px) {
  .mask-wrap {
    z-index: 10000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.8);
    &.closeMask {
      display: none;
    }
    .banner {
      width: 498px;
      height: 500px;
      background: url('../image/banner_bg.png') #e3eefd no-repeat;
      background-size: 100%;
      border-radius: 24px;
      padding: 30px 40px;
      box-sizing: border-box;
      position: relative;
      .banner-close {
        position: absolute;
        top: 0;
        right: -40px;
        width: 18px;
        height: 18px;
        background: url('../image/close_2.png') no-repeat;
        background-size: 100%;
        cursor: pointer;
      }
      .banner-content {
        width: 100%;
        height: fit-content;
        font-family: Poppins;
        font-size: 22px;
        font-weight: 400;
        line-height: 32px;
        text-align: left;
        color: #343434;
        span {
          font-weight: 700;
          font-size: 20px;
          color: #ff3030;
          cursor: pointer;
          font-family: Poppins-SemiBold;
        }
        .title {
          font-family: Poppins-SemiBold;
          font-size: 33px;
          font-weight: 600;
          line-height: 49.5px;
          text-align: center;
          color: #ff3030;
        }
        .sub-title {
          font-family: Poppins-SemiBold;
          color: #343434;
          font-size: 21px;
          font-weight: 600;
          line-height: 31.5px;
          text-align: left;
          padding: 10px 0 0;
        }
        .content {
          font-family: Poppins;
          font-size: 18px;
          font-weight: 400;
          line-height: 27px;
          text-align: left;
          color: #343434;
        }
      }
      .banner_btn {
        width: 360px;
        height: 77px;
        box-sizing: border-box;
        background: url('../image/btn_bg.png');
        background-size: 100%;
        text-align: center;
        font-size: 22.4px;
        line-height: 65px;
        font-weight: 700;
        color: #fff;
        cursor: pointer;
        margin: 10px auto 0;
      }
    }
  }
}
@media screen and (max-width:1200px) {
  .mask-wrap {
    z-index: 10000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    background: rgba(0, 0, 0, 0.8);
    overflow: auto;
    &.closeMask {
      display: none;
    }
    .banner {
      width: 320px;
      min-height: 383px;
      height: fit-content;
      background: url('../image/banner_bg.png') #fafbff no-repeat;
      background-size: 100%;
      border-radius: 24px;
      padding: 20px;
      box-sizing: border-box;
      position: relative;
      .banner-close {
        position: absolute;
        top: 10px;
        right: 10px;
        width: 20px;
        height: 20px;
        background: url('../image/close_3.png') no-repeat;
        background-size: 100%;
        cursor: pointer;
      }
      .banner-content {
        width: 100%;
        height: fit-content;
        font-family: Poppins;
        font-size: 22px;
        font-weight: 400;
        line-height: 32px;
        text-align: left;
        color: #343434;
        span {
          font-weight: 700;
          font-size: 15px;
          color: #ff3030;
          cursor: pointer;
          font-family: Poppins-SemiBold;
        }
        .title {
          font-family: Poppins-SemiBold;
          font-size: 18px;
          font-weight: 600;
          line-height: 27px;
          text-align: center;
          color: #ff3030;
        }
        .sub-title {
          font-family: Poppins-SemiBold;
          font-size: 14px;
          font-weight: 600;
          line-height: 21px;
          text-align: left;
          padding: 10px 0 6px;
        }
        .content {
          font-family: Poppins;
          font-size: 13px;
          font-weight: 400;
          line-height: 19.5px;
          text-align: left;
          color: #343434;
        }
        .cotent-new {
          word-break: break-all;
          display: inline-block;
        }
      }
      .banner_btn {
        width: 238px;
        height: 51px;
        box-sizing: border-box;
        background: url('../image/btn_bg.png');
        background-size: 100%;
        text-align: center;
        font-size: 14px;
        line-height: 40px;
        font-weight: 700;
        color: #fff;
        cursor: pointer;
        margin: 10px auto 0;
      }
    }
  }
}
