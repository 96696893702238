
.common-toast-wrap {
  position: fixed;
  z-index: 999;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  max-width: 80%;
  height: auto;
  box-sizing: border-box;
  padding: 0.2rem;
  background: rgba(0, 0, 0, 0.8);
  color: #ffffff;
  text-align: center;
  border-radius: 8px;
  font-size: 0.24rem;
}
@media screen and (min-width: 1000px) {
  .common-toast-wrap {
    padding: 15px;
    font-size: 20px;
  }
}
