
.letter-cell {
    display: flex;
    padding: .14rem 0;
}

.cell-img {
    width: 0.27rem;
    height: 0.27rem;
    img {
        height: 100%;
        display: inline-block;
    }
}
