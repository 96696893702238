
.ph-input-wrap {
    display: flex;
    position: relative;
    align-items: center;
    width: 100%;
    font-size: .27rem;
}

.ph-input_content {
    display: flex;
    flex: 1;
}

.ph-input_suffix {
    padding-right: 0.2rem;
}
.ph-input_close {
    cursor: pointer;
}
.ph-input {
    width: 100%;
    flex: 1;
    outline: none;
    text-align: left;
    background: transparent;
    font-size: .27rem;
    &::placeholder {
        color:#C0C0C0 ;
    }
}

.input_close {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: #c0c4cc;

    img {
        width: 0.25rm;
        height: 0.25rem;
    }
}
.split-line {
    border-right: .01rem solid #bcc4d4;
    padding-left: .06rem;
}
