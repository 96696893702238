
.common-textarea {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.common-textarea_content {
    box-sizing: content-box;
    width: 93.75%;
    height: 1.75rem;
    padding: 0.2rem;
    background: #FAFAFA;
    border-radius: 0.07rem;
    border: 0.01rem solid #EDEEF5;
    resize: none;
    outline: none;
}

.active {
    border-color: #FF564E;
}

.error-tips {
    color: #FF564E;
    font-size: .2rem;
    margin-top: .1rem;
}
