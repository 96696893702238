
@media screen and (min-width:1200px) {
  .footer {
    width: 100%;
    background: #181f3a;
    .footer-box {
      width: 1080px;
      margin: 0 auto;
      padding: 45px 0 20px;
      .contact-us {
        width: 40%;
      }
      .footer-content {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        padding-bottom: 20px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.115);
        .cu-title {
          font-size: 18px;
          font-family: Poppins-SemiBold;
          font-weight: 500;
          color: #fff;
          margin-bottom: 11px;
        }
        .flex-start {
          display: flex;
          justify-content: flex-start;
          margin-bottom: 11px;
        }
        .cu-text {
          cursor: pointer;
          font-size: 16px;
          font-family: Poppins;
          font-weight: 500;
          color: rgba(255, 255, 255, 0.7);
          line-height: 28px;
          transition: all 0.5s;
        }
        .cu-text:hover {
          color: #ffffff;
        }
        .download-box {
          display: flex;
          flex-direction: column;
        }
        .download-box-wap {
          display: none;
        }
        .download-icon {
          width: 130px;
          object-fit: contain;
          cursor: pointer;
        }
        .click_apk {
          font-family: Poppins;
          font-size: 14px;
          font-weight: 400;
          text-align: center;
          color: #1483ff;
          text-decoration: underline;
          padding-top: 8px;
        }
        .mrr12 {
          margin-right: 12px;
        }
      }
      .bottom {
        width: 100%;
        font-size: 14px;
        font-weight: 500;
        color: rgba(255, 255, 255, 0.7);
        line-height: 26px;
        padding: 24px 0;
      }
      .last-light {
        color: #fff;
      }
      .copyright {
        margin-top: 22px;
        text-align: center;
        font-size: 14px;
        font-family: Poppins;
        font-weight: 500;
        color: rgba(173, 173, 173, 0.51);
        line-height: 24px;
      }
      a {
        display: block;
      }
    }
  }
  .tip {
    box-sizing: border-box;
    padding: 30px 137px;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: #837aff;
    border: 1px solid #979797;
    overflow: hidden;
    transform: translateY(100%);
    transition: all 0.5s linear;
    font-weight: 500;
    text-align: left;
    z-index: 999;
    &-show {
      transform: translateY(0);
    }
    &__text {
      color: #ffffff;
      font-size: 16px;
      font-family: Poppins;
      line-height: 24px;
      &__link {
        color: #ffd874;
        text-decoration: underline;
      }
    }
    &__confirm {
      margin: 15px auto 0;
      width: 160px;
      height: 42px;
      background-color: #ffffff;
      border-radius: 22px;
      color: #333;
      font-size: 13px;
      font-family: Poppins-Medium;
      line-height: 42px;
      text-align: center;
      cursor: pointer;
      user-select: none;
    }
    &__close {
      position: absolute;
      top: 36px;
      right: 72px;
      width: 18px;
      height: 18px;
      cursor: pointer;
    }
  }
}
@media screen and (max-width:1200px) {
  .footer {
    background: #181f3a;
    width: 100%;
    padding: 0.34rem;
    box-sizing: border-box;
    .footer-box {
      width: 100%;
      .footer-content {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: flex-start;
        padding-bottom: 0.34rem;
        border: none;
        .contact-us {
          padding-bottom: 0.41rem;
        }
        .cu-title {
          font-size: 0.24rem;
          font-family: Poppins-SemiBold;
          font-weight: 500;
          color: rgba(255, 255, 255, 0.922);
          margin-bottom: 0.27rem;
        }
        .flex-start {
          display: flex;
          justify-content: flex-start;
        }
        .cu-text {
          cursor: pointer;
          font-size: 0.2rem;
          font-family: Poppins-SemiBold;
          font-weight: 500;
          color: rgba(173, 173, 173, 0.51);
          line-height: 0.41rem;
        }
        .cu-text:hover {
          color: #ffffff;
        }
        .download-box {
          display: none;
        }
        .download-box-wap {
          margin-top: 0.41rem;
          display: flex;
          flex-direction: column;
          gap: 0.14rem;
          .flex-wrap {
            display: flex;
            gap: 0.14rem;
          }
          .download-icon {
            object-fit: contain;
            height: 0.88rem;
          }
          .mrr12 {
            margin-right: 0.27rem;
          }
        }
        .click_apk {
          color: #1483ff;
          padding-top: 0.27rem;
          text-decoration: underline;
        }
      }
      .bottom {
        font-size: 0.2rem;
        font-family: Poppins-SemiBold;
        font-weight: 500;
        color: rgba(255, 255, 255, 0.7);
        line-height: 0.32rem;
        margin-bottom: 0.34rem;
      }
      .last-light {
        color: #fff;
      }
      .copyright {
        margin-top: 0;
        text-align: center;
        font-size: 0.2rem;
        font-family: Poppins-SemiBold;
        font-weight: 500;
        color: rgba(173, 173, 173, 0.51);
        line-height: 0.31rem;
      }
      a {
        display: block;
      }
    }
  }
  .tip {
    box-sizing: border-box;
    padding: 0.15rem 0.38rem 0.26rem;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: #837aff;
    overflow: hidden;
    transform: translateY(100%);
    transition: all 0.5s linear;
    font-weight: 500;
    text-align: left;
    z-index: 999;
    &-show {
      transform: translateY(0);
    }
    &__text {
      color: #ffffff;
      font-size: 0.2rem;
      font-family: Poppins-Medium;
      line-height: 0.31rem;
      &__link {
        color: #ffd874;
        text-decoration: underline;
      }
    }
    &__confirm {
      margin: 0.06rem auto 0;
      width: 2.59rem;
      height: 0.55rem;
      background-color: #ffffff;
      border-radius: 0.26rem;
      color: #333;
      font-size: 0.24rem;
      font-family: Poppins-Medium;
      line-height: 0.55rem;
      text-align: center;
      cursor: pointer;
      user-select: none;
    }
    &__close {
      position: absolute;
      top: 0.23rem;
      right: 0.23rem;
      width: 0.23rem;
      height: 0.23rem;
      cursor: pointer;
    }
  }
}
