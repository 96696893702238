
.common-check_wrap {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
}

.common-check_item {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 0.41rem;
}

.common-check_icon {
  width: 0.27rem;
  margin-right: 0.14rem;

  img {
    display: inline-block;
    width: 100%;
  }
}
.common-check_icon_pc {
  width: 14px;
  height: 14px;
  margin-right: 7px;
  float: left;
  margin-top: 2px;
}
.common-check_label {
  text-align: left;
  font-size: 0.2rem;
  font-family: SFNS-Regular, SFNS;
  font-weight: 400;
  color: #7d7e94;
}

.flex1 {
  flex: 1;
}

.ommon-check_item {
  display: flex;
}
